import React, { useEffect, useState } from 'react'
import { getBlingsData, BlingsDataInterface, transformBlingsData, initialState } from '../data/api/blings'

const PROJECT_ID = 'copy772b2e08-982b-484a-9803-8070acabd402'
const BLINGS_URL = 'https://assets.blings.io/sdk/blingsSDK_v3-0-3.js'

declare global {
  interface Window {
    BlingsPlayer: any
  }
}

// Check to see if Browser has rendered
const isBrowser = () => typeof window !== 'undefined'
const isDocument = () => typeof document !== 'undefined'

const BlingsPlayer = () => {
  const [data, setData] = useState<BlingsDataInterface>(initialState)

  const itemId = isBrowser() ? window.location.search.replace('?itemId=', '') : ''

  async function fetchDataAndCreateBlings() {
    const fetchedData = await getBlingsData(itemId)
    window.BlingsPlayer.create({
      settings: {
        container: isDocument() ? document.getElementById('vid-holder') : null,
        cinematic: true,
      },
      data: transformBlingsData(fetchedData),
      project: {
        projectId: PROJECT_ID,
      },
      scenes: ['Main Scene'],
    })
    setData(fetchedData)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.addEventListener('load', () => {
      fetchDataAndCreateBlings()
    })
    script.src = BLINGS_URL
    document.getElementsByTagName('head')[0].appendChild(script)
  }, [])

  const blingsDisabled = <h1 style={{ color: 'white' }}>This page is currently unavailable</h1>

  const { hideBlingsPlayer } = data

  return data.error || !isDocument() ? null : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {(() => {
        const element: any = document.getElementsByClassName('intercom-lightweight-app')
        while (element.length > 0) {
          element && element[0] ? element[0].parentNode.removeChild(element[0]) : null
        }
        return true
      })()}
      {hideBlingsPlayer ? blingsDisabled : <div id="vid-holder" />}
    </div>
  )
}
export default BlingsPlayer
